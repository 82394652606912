import { Tag } from 'antd';
import React from 'react';
import { dataGoldRequestDetails } from '../../Actions/Data';

interface GoldRequestDetailsHeaderProps {
  id?: string;
  mode: string;
  status: string;
  client: string;
}
const GoldRequestsDetailsHeader: React.FC<GoldRequestDetailsHeaderProps> = ({
  id,
  mode,
  status,
  client,
}) => {
  return (
    <div className="flex flex-row justify-center items-center gap-2  lg:gap-6 overflow-x-scroll  ">
      <h1 className="ps-20 md:ps-0 text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl">
        Gold Request #{id}
      </h1>
      <h1
        className={`${mode === 'light' && 'bg-[#E0F0FE]'} text-sm xl:text-[1rem] 2xl:text-lg font-semibold py-1 px-2 rounded-lg `}
      >
        {client}
      </h1>
      <Tag className="gap-3 px-[0.675rem] py-1 rounded-lg font-medium">
        <span
          className={` inline-block w-2 h-2 ${status === 'approved' ? 'bg-[#17B26A]' : status === 'pending' ? 'bg-[#F79009]' : 'bg-[#0F95E8]'} rounded-full me-2`}
        />
        {status}
      </Tag>
    </div>
  );
};

export default GoldRequestsDetailsHeader;
