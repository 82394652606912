import * as React from 'react';
const DollerSign = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#344054"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.667}
      d="M6.583 12.222c0 1.074.871 1.945 1.945 1.945h1.805a2.083 2.083 0 0 0 0-4.167H8.667a2.083 2.083 0 1 1 0-4.167h1.805c1.074 0 1.945.87 1.945 1.945M9.5 4.583v1.25m0 8.334v1.25M17.833 10a8.333 8.333 0 1 1-16.666 0 8.333 8.333 0 0 1 16.666 0Z"
    />
  </svg>
);
export default DollerSign;
