import React, { useContext, useEffect, useState } from 'react';
import {
  IResourceComponentsProps,
  BaseRecord,
  useExport,
} from '@refinedev/core';
import { List, ShowButton, useTable } from '@refinedev/antd';
import { Table, Space, Tag, Button } from 'antd';
import { Search } from '../../components/Search';

import Filtration from '../../components/Filtration';
import { ColorModeContext } from '../../contexts/color-mode';
import FilterBar from '../../components/FiltersBar';
import ApprovalIcon from '../../Assests/Svgs/ApprovalIcon';
import GoldRequestsDetailsModal from '../../components/Modals/GoldRequestsDetailsModal';
import { ExportCsvButton } from '../../components/buttons/CustomExportCsvButton';
import StarIcon from '../../Assests/Svgs/StarIcon';
import BulkPointsManagement from '../../components/buttons/ExportButtonGoldRequests';
interface Filter {
  field: string;
  value: string;
}
export const GoldRequestsList: React.FC<IResourceComponentsProps> = () => {
  const [isEmptySearch, setIsEmptySearch] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<any>([]);
  const [filtration, setFiltration] = useState<Filter[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [approveId, setApproveId] = useState<number>(0);
  const [importDoctorModalOpen, setImportDoctorModalOpen] = useState(false);

  const updateImportDoctorModalOpen = (value: boolean) => {
    setImportDoctorModalOpen(value);
  };
  const showModal = (id: number) => {
    setApproveId(id);
    setIsModalOpen(!isModalOpen);
  };
  const { mode } = useContext(ColorModeContext);
  const {
    searchFormProps,
    tableProps,
    setFilters,
    filters,
    tableQueryResult: { refetch },
  } = useTable({
    // @ts-ignore
    onSearch: (values: {
      search_field: any;
      system_configs_id: string;
      gold_type: string;
      delivery_type: string;
      status: string;
      Speciality_name_en: string[];
      start_date: string;
      end_date: string;
    }) => {
      let filters = [
        {
          field: 'search_field',
          operator: 'eq',
          value: values.search_field,
        },
        {
          field: 'Specialty_name_en',
          operator: 'eq',
          value: values.Speciality_name_en,
        },

        {
          field: 'start_date',
          operator: 'eq',
          value: values.start_date,
        },
        {
          field: 'end_date',
          operator: 'eq',
          value: values.end_date,
        },
      ];
      if (values.system_configs_id && values.system_configs_id !== 'All') {
        filters.push({
          field: 'system_configs_id',
          operator: 'eq',
          value:
            values.system_configs_id == 'IDH First Class'
              ? 288
              : values.system_configs_id == 'IDH Excellence'
                ? 289
                : values.system_configs_id === 'Sipes'
                  ? 279
                  : values.system_configs_id === 'Shell' && 292,
        });
      } else {
        filters = filters.filter(
          (filter) => filter.field !== 'system_configs_id'
        );
      }

      if (values.gold_type && values.gold_type !== 'All') {
        filters.push({
          field: 'gold_type',
          operator: 'eq',
          value:
            values.gold_type === 'Quarter Gold Pound'
              ? 'Quarter_Gold_Pound'
              : values.gold_type == 'Half Gold Pound'
                ? 'Half_Gold_Pound'
                : values.gold_type == 'Gold Pound'
                  ? 'Gold_Pound'
                  : values.gold_type === 'Ten Grams Pound'
                    ? 'Ten_Grams_Bar'
                    : values.gold_type === 'Five Grams Pound'
                      ? 'Five_Grams_Bar'
                      : values.gold_type === 'Ounce Bar' && 'Ounce_Bar',
        });
      } else {
        filters = filters.filter((filter) => filter.field !== 'gold_type');
      }
      if (values.delivery_type && values.delivery_type !== 'All') {
        filters.push({
          field: 'delivery_type',
          operator: 'eq',
          value:
            values.delivery_type == 'Delivery'
              ? 'delivery'
              : values.delivery_type == 'Store Pickup' && 'pickup',
        });
      } else {
        filters = filters.filter((filter) => filter.field !== 'delivery_type');
      }

      if (values.status && values.status !== 'All') {
        filters.push({
          field: 'status',
          operator: 'eq',
          value:
            values.status == 'Approved'
              ? 'approved'
              : values.status == 'Pending'
                ? 'pending'
                : values.status == 'Picked Up' && 'rejected',
        });
      } else {
        filters = filters.filter((filter) => filter.field !== 'status');
      }
      if (values.start_date && values.end_date) {
        filters.push({
          field: 'start_date',
          operator: 'eq',
          value: values.start_date,
        });
        filters.push({
          field: 'end_date',
          operator: 'eq',
          value: values.end_date,
        });
      } else {
        filters = filters.filter(
          (filter) =>
            filter.field !== 'start_date' && filter.field !== 'end_date'
        );
      }
      return filters;
    },

    syncWithLocation: true,
    errorNotification: () => {
      return {
        message: `No results found`,
        type: 'error',
      };
    },
  });
  const { triggerExport, isLoading: exportLoading } = useExport({
    resource: 'gold-requests',
    filters: filters,
    mapData: (data) => {
      return {
        id: data.id || 'Not provided',
        account_id: data.account_id || 'Not provided',
        shared_wallet_id: data.shared_wallet_id || 'Not provided',
        SystemConfigID: data.SystemConfigID || 'Not provided',
        Date: data.created_at || 'Not provided',
        UserName: data.Account.name || 'Not provided',
        Pickup_person: data?.shared_wallet?.name || 'Not provided',
        MobileNumber: data?.Account?.phone_number || 'Not provided',
        Pickup_Person_Number: data?.shared_wallet?.phone || 'Not provided',
        delivery_type: data.delivery_type || 'Not provided',
        address: data.address || 'Not provided',
        branch_address: data.branch_address || 'Not provided',
        gold_type: data.gold_type || 'Not provided',
        city: data.city || 'Not provided',
        status: data.status || 'Not provided',
        PointValues: data.proposed_price || 'Not provided',
        Price: data.final_price || 'Not provided',
        is_deleted: data.is_deleted || 'Not provided',
      };
    },
  });
  const handleEmptySearch = () => {
    setIsEmptySearch(true);
  };
  const removeFilter = (field: string, value: string) => {
    setFiltration((prevFilters) =>
      prevFilters.filter(
        (filter) => !(filter.field === field && filter.value === value)
      )
    );
    setFilters((currentFilters) => {
      // Preserve the user filter and clear other filters
      //@ts-ignore
      return currentFilters.filter((filter: any) => {
        return filter.field !== field;
      });
    });
    refetch();
  };
  const handleReset = () => {
    searchFormProps.form?.resetFields();
    //@ts-ignore
    setFilters((currentFilters) => {
      // Preserve the user filter and clear other filters
      return currentFilters.filter(
        (filter: { field: string }) => filter.field === 'account_type'
      );
    }, 'replace');
    refetch();
  };
  if (searchResults?.length > 0) {
    tableProps.dataSource = searchResults;
  } else if (isEmptySearch) {
    tableProps.dataSource = [];
  }
  const paginationConfig =
    tableProps.dataSource !== undefined && searchResults.length > 0
      ? {
          total: searchResults.length > 0 ? searchResults.length : undefined,
        }
      : undefined;

  useEffect(() => {
    handleReset();
  }, []);
  return (
    <List
      headerButtons={
        <BulkPointsManagement
          title="Bulk Points Management"
          showImportButton
          showExtractButton
          onImportClick={updateImportDoctorModalOpen}
          onExtractClick={triggerExport}
          exportLoading={exportLoading}
        />
      }
    >
      <div className=" flex flex-col justify-center items-center 2xl:flex-row 2xl:justify-between  w-full overflow-x-hidden">
        <Search
          path="gold-requests"
          setSearchResults={setSearchResults}
          searchResults={searchResults}
          onEmptySearch={handleEmptySearch}
          onCancelSearch={() => setIsEmptySearch(false)}
          className="self-center max-md:w-full  md:ms-[30%] lg:ms-[33%] 2xl:ms-0"
        />
        <Filtration
          setFiltration={setFiltration}
          searchFormProps={searchFormProps}
          refetch={refetch}
          setFilters={setFilters}
        />
      </div>
      <FilterBar
        filtration={filtration}
        removeFilter={removeFilter}
        setFiltration={setFiltration}
        handleReset={handleReset}
      />
      <Table
        // dataSource={goldRequestsData}
        {...tableProps}
        rowKey="id"
        className={`overflow-scroll ${mode === 'light' ? 'bg-white' : 'bg-[#141414]'} `}
        pagination={paginationConfig}
      >
        <Table.Column
          dataIndex="id"
          title="Request ID"
          className="font-medium"
          render={(value: string) => (
            <>
              <span>{value}</span>
            </>
          )}
        />

        <Table.Column
          dataIndex={['systemConfigs', 'BundleID']}
          title="Client"
          render={(value: string) => (
            <>
              <span>{value ? value.split('.')[0] : '-'}</span>
            </>
          )}
        />
        <Table.Column
          title="Date"
          dataIndex="created_at"
          render={(value: string) => (
            <>
              <span>
                {new Date(value).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
              </span>
            </>
          )}
        />

        <Table.Column
          dataIndex="gold_type"
          title="Gold Type"
          render={(value: any) => (
            <>
              <span>{value}</span>
            </>
          )}
        />
        <Table.Column
          dataIndex="proposed_price"
          title="Point Values"
          render={(value: string) => (
            <>
              <span>{value}</span>
            </>
          )}
        />
        <Table.Column
          dataIndex="final_price"
          title="Price"
          render={(value: string) => (
            <>
              <span>{value ? value : '-'}</span>
            </>
          )}
        />
        <Table.Column
          title="Mobile Number"
          dataIndex={['Account', 'shared_wallet']} // Use an array for nested paths
          render={(
            _,
            record: {
              Account: { phone_number: number };
              shared_wallet: { phone: number };
            }
          ) => (
            <>
              <span>
                {record?.shared_wallet
                  ? record?.shared_wallet?.phone
                  : record?.Account?.phone_number}
              </span>
            </>
          )}
        />
        <Table.Column
          title="User name"
          dataIndex={['Account', 'name']}
          render={(value: string) => (
            <>
              <span>{value}</span>
            </>
          )}
        />
        <Table.Column
          title="Pickup person"
          dataIndex={['shared_wallet', 'Account', 'account_id']}
          render={(
            _,
            record: {
              shared_wallet: {
                name: string;
              };
              Account: { name: string };
              account_id?: string;
            }
          ) => (
            <>
              <span>
                {record?.shared_wallet
                  ? record?.shared_wallet?.name
                  : record?.Account?.name}
              </span>
            </>
          )}
        />
        <Table.Column
          title="Delivery Method"
          dataIndex="delivery_type"
          render={(value: string) => (
            <>
              <span>{value}</span>
            </>
          )}
        />

        <Table.Column
          title="Address"
          dataIndex={['delivery_type', 'address', 'branch_address']} // Ensure the required data is available
          render={(
            _,
            record: {
              delivery_type: string;
              address: string;
              branch_address?: string;
            }
          ) => (
            <>
              <span>
                {record?.delivery_type === 'pickup'
                  ? record?.branch_address
                  : record?.address}
              </span>
            </>
          )}
        />

        <Table.Column
          title="Status"
          dataIndex="status"
          render={(value: string) => (
            <Tag>
              <span
                className={` inline-block w-2 h-2 ${value.toLowerCase() === 'approved' ? 'bg-[#17B26A]' : value.toLowerCase() === 'pending' ? 'bg-[#F79009]' : 'bg-[#0F95E8]'} rounded-full me-2`}
              />
              {value}
            </Tag>
          )}
        />
        <Table.Column
          dataIndex="actions"
          title="Actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <ShowButton hideText size="small" recordItemId={record?.id} />
              <ApprovalIcon
                className={` ${record?.status === 'pending' ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                onClick={() => {
                  record.status === 'pending' && showModal(record.id as number);
                }}
              />
            </Space>
          )}
        />
      </Table>
      <GoldRequestsDetailsModal
        isModalOpen={isModalOpen}
        showModal={showModal}
        mode={mode}
        gold_request_id={approveId}
        refetch={refetch}
      />
    </List>
  );
};
