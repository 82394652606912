import React from 'react';
import {
  CloudDownloadOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons';
import ImportIcon from '../../Assests/Svgs/ImportIcon';
import ExtractIcon from '../../Assests/Svgs/ExtractIcon';
import { Button } from 'antd';

interface BulkPointsManagementProps {
  title: string;
  showImportButton?: boolean;
  showExtractButton?: boolean;
  onImportClick: (value: boolean) => void;
  onExtractClick?: () => void;
  exportLoading?: boolean;
}

const BulkPointsManagement: React.FC<BulkPointsManagementProps> = ({
  showImportButton = false,
  showExtractButton = true,
  onExtractClick,
  exportLoading,
  onImportClick,
}) => {
  return (
    <div className="flex items-center gap-x-2">
      {showExtractButton && (
        <Button
          onClick={onExtractClick}
          type="primary"
          icon={<CloudDownloadOutlined />}
        >
          {exportLoading ? (
            <Loading3QuartersOutlined spin />
          ) : (
            <>Download CSV</>
          )}
        </Button>
      )}
    </div>
  );
};

export default BulkPointsManagement;
