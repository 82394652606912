import React from 'react';
interface Props {
  header: string;
  icon?: JSX.Element;
  mode: string;
  descreption?: string;
  priceInput?: boolean;
}
const GoldRequestsDetailsInputs: React.FC<Props> = ({
  mode,
  descreption,
  header,
  icon,
  priceInput,
}) => {
  return (
    <div className="w-full xl:w-[48%] flex flex-col justify-center items-start gap-4">
      <h1 className="text-xs xl:text-sm 2xl:text-lg font-semibold ">
        {header}
      </h1>
      <div
        className={`flex flex-row justify-start items-center bg-[#F9FAFB] ${mode !== 'light' && 'text-black'} w-full py-4 px-5 rounded-xl gap-3 `}
      >
        {icon && icon}
        {priceInput ? (
          <div className="w-full flex flex-row justify-between items-center">
            <h1 className=" text-xs lg:text-sm 2xl:text-lg font-semibold ">
              {descreption ? descreption : '-'}
            </h1>
            <h1 className="py-1 px-2 rounded-2xl text-xs lg:text-sm 2xl:text-lg font-semibold bg-[#F0F8FF] border-[1px] border-[#EAECF0]">
              {' '}
              EGP
            </h1>
          </div>
        ) : (
          <h1 className=" text-xs lg:text-sm 2xl:text-lg font-semibold ">
            {descreption ? descreption : '-'}
          </h1>
        )}
      </div>
    </div>
  );
};

export default GoldRequestsDetailsInputs;
