import React, { useContext, useEffect, useState } from 'react';
import { IResourceComponentsProps, useShow } from '@refinedev/core';
import { Show } from '@refinedev/antd';
import { ColorModeContext } from '../../contexts/color-mode';
import GoldRequestsDetailsInputs from '../../components/GoldRequestsDetailsInputs';
import { useParams } from 'react-router-dom';
import GoldRequestsDetailsModal from '../../components/Modals/GoldRequestsDetailsModal';
import GoldRequestsDetailsHeader from '../../components/headers/GoldRequestsDetailsHeader';
import GoldRequestsHeaderButtons from '../../components/buttons/GoldRequestsHeaderButtons';
import DateIcon from '../../Assests/Svgs/DateIcon';
import CoinsSvg from '../../Assests/Svgs/CoinsSvg';
import XIcon from '../../Assests/Svgs/xIcon';
import DollerSign from '../../Assests/Svgs/DollerSign';
import { LoadingOutlined } from '@ant-design/icons';

export const GoldRequestsShow: React.FC<IResourceComponentsProps> = () => {
  const { mode } = useContext(ColorModeContext);
  const { id } = useParams();
  const { queryResult } = useShow();
  const { data, isLoading, refetch } = queryResult;

  const [goldRequestData, setGoldRequestData] = useState<any>();

  useEffect(() => {
    setGoldRequestData(queryResult?.data?.data);
  }, [queryResult, id]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  if (!goldRequestData) {
    return <LoadingOutlined />; // You can replace this with a loader
  }

  return (
    <Show
      title={
        <GoldRequestsDetailsHeader
          id={id}
          mode={mode}
          status={goldRequestData.status}
          client={goldRequestData.systemConfigs.BundleID.split('.')[0]}
        />
      }
      headerButtons={
        <GoldRequestsHeaderButtons
          showModal={showModal}
          isApproved={
            goldRequestData.status === 'approved' ||
            goldRequestData.status === 'rejected'
          }
        />
      }
    >
      {/* Request Details */}
      <div className=" border-b-2 border-[#EAECF0] pb-4 ">
        <h1 className="text-sm lg:text-lg 2xl:text-2xl font-semibold pt-6 pb-4">
          Request Details
        </h1>
        <div className="flex flex-wrap w-full gap-8">
          <GoldRequestsDetailsInputs
            icon={<DateIcon />}
            header={'Date'}
            mode={mode}
            descreption={new Date(
              goldRequestData?.created_at
            ).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          />
          <GoldRequestsDetailsInputs
            icon={<CoinsSvg />}
            header={'Gold type'}
            mode={mode}
            descreption={goldRequestData?.gold_type}
          />
          <GoldRequestsDetailsInputs
            icon={<XIcon />}
            header={'Points value'}
            mode={mode}
            descreption={goldRequestData?.proposed_price}
          />
          <GoldRequestsDetailsInputs
            icon={<DollerSign />}
            header={'Price'}
            mode={mode}
            descreption={goldRequestData?.final_price}
            priceInput={true}
          />
        </div>
      </div>

      {/* User Details */}
      <div className=" border-b-2 border-[#EAECF0] pb-4 ">
        <h1 className="text-sm lg:text-lg 2xl:text-2xl font-semibold pt-6 pb-4">
          User details
        </h1>
        <div className="flex flex-wrap w-full gap-8">
          <GoldRequestsDetailsInputs
            header={'User name'}
            mode={mode}
            descreption={goldRequestData?.Account.name}
          />
          <GoldRequestsDetailsInputs
            header={'Mobile number'}
            mode={mode}
            descreption={
              goldRequestData?.shared_wallet
                ? goldRequestData.shared_wallet.phone
                : goldRequestData.Account.phone_number
            }
          />
          <GoldRequestsDetailsInputs
            header={'Pickup person'}
            mode={mode}
            descreption={
              goldRequestData?.shared_wallet
                ? goldRequestData?.shared_wallet?.name
                : goldRequestData?.Account?.name
            }
          />
          <GoldRequestsDetailsInputs
            header={'Pickup person number'}
            mode={mode}
            descreption={goldRequestData?.Account.phone_number}
          />
        </div>
      </div>

      {/* Delivery Details */}
      <div className=" border-b-2 border-[#EAECF0] pb-4 ">
        <h1 className="text-sm lg:text-lg 2xl:text-2xl font-semibold pt-6 pb-4">
          Delivery
        </h1>
        <div className="flex flex-wrap w-full gap-8">
          <GoldRequestsDetailsInputs
            header={'Delivery type'}
            mode={mode}
            descreption={goldRequestData?.delivery_type}
          />
          <GoldRequestsDetailsInputs
            header={'Address'}
            mode={mode}
            descreption={
              goldRequestData.delivery_type === 'pickup'
                ? goldRequestData?.branch_address
                : goldRequestData?.address
            }
          />
        </div>
      </div>

      {/* Modal */}
      <GoldRequestsDetailsModal
        isModalOpen={isModalOpen}
        showModal={showModal}
        mode={mode}
        gold_request_id={Number(id)}
        refetch={refetch}
      />
    </Show>
  );
};
