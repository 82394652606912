import React, { useState } from 'react';
import ApprovalModalIcon from '../../Assests/Svgs/ApprovalModalIcon';
import { Button, Input, message, Modal } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { approveGoldRequestAction } from '../../Actions/ConfigsActions';

interface ModalProps {
  isModalOpen: boolean;
  showModal: any;
  mode: string;
  gold_request_id: number;
  refetch: () => void;
}
const GoldRequestsDetailsModal: React.FC<ModalProps> = ({
  isModalOpen,
  showModal,
  mode,
  gold_request_id,
  refetch,
}) => {
  const [price, setPrice] = useState<number>(0);

  const handleApproved = () => {
    approveGoldRequestAction(gold_request_id, price, refetch, showModal);

    message.success({
      content: (
        <div className="flex items-center gap-2">
          {/* Success check icon */}
          <CheckCircleOutlined
            style={{ fontSize: '18px', color: '#52c41a' }}
            className="self-start"
          />

          {/* Text content next to the icon */}
          <div className="text-start">
            <h1 className="text-base font-semibold">Successfully approved</h1>
            <h1 className="text-sm">Request approved and status is updated.</h1>
          </div>
        </div>
      ),
      icon: <span />,
    });
  };
  return (
    <Modal
      open={isModalOpen}
      onCancel={showModal}
      footer={null} // Remove default footer buttons
      className="xl:pt-5 2xl:pt-40"
    >
      <div className="w-full h-full relative flex flex-col justify-center p-3 ">
        <ApprovalModalIcon />
        <h1 className=" pt-4 text-lg lg:text-xl 2xl:text-2xl font-semibold">
          Approve Gold Request
        </h1>
        <h3 className="pt-1 text-xs lg:text-sm 2xl:text-lg font-normal pb-5">
          Please enter points & price to be deducted from user’s wallet.
        </h3>
        <div className="flex flex-col gap-2 pb-5 text-xs lg:text-sm 2xl:text-lg font-normal  ">
          <h3>Points value*</h3>
          <Input
            className="h-12 placeholder:text-[#667085]"
            placeholder="Enter points value"
            onChange={(e) => setPrice(Number(e.target.value))}
            type="number"
            value={price !== 0 ? price : ''}
            min={0}
          />
        </div>
        <div className="flex flex-col gap-2 pb-5 text-xs lg:text-sm 2xl:text-lg font-normal">
          <h3>Price*</h3>
          <div className="w-full flex flex-row justify-center items-center rounded-xl ">
            <h1
              className={`flex justify-center items-center  border-[1px] border-e-0  h-12 px-4 rounded-s-xl text-xs lg:text-sm 2xl:text-lg ${mode !== 'light' && 'border-[#424242]'}`}
            >
              EGP
            </h1>
            <Input
              className="h-12 placeholder:text-[#667085] rounded-s-none "
              placeholder="Enter equivalent price"
              onChange={(e) => setPrice(Number(e.target.value) * 10)}
              value={price !== 0 ? price / 10 : ''}
              type="number"
              min={0}
            />
          </div>
        </div>

        {/* Custom footer buttons */}
        <div className="flex flex-col gap-4 mt-4">
          <Button
            type="primary"
            className={`w-full h-12 text-sm lg:text-lg 2xl:xl font-semibold ${!price ? 'bg-[#F2F4F7] text-[#98A2B3]' : ''}`}
            disabled={!price}
            onClick={handleApproved}
          >
            Approve
          </Button>
          <Button
            className="w-full h-12 text-sm lg:text-lg 2xl:xl font-semibold"
            onClick={showModal} // Close modal
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default GoldRequestsDetailsModal;
