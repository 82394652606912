import * as React from 'react';
const CoinsSvg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#344054"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.667}
      d="M10.5 14.167a4.167 4.167 0 1 0 8.333 0 4.167 4.167 0 0 0-8.333 0Zm0 0c0-.938.31-1.804.833-2.5v-7.5m-.833 10c0 .688.167 1.336.462 1.908-.702.593-2.324 1.008-4.212 1.008-2.531 0-4.583-.746-4.583-1.666V4.167m9.166 0c0 .92-2.052 1.666-4.583 1.666s-4.583-.746-4.583-1.666m9.166 0c0-.92-2.052-1.667-4.583-1.667s-4.583.746-4.583 1.667m0 7.5c0 .92 2.052 1.666 4.583 1.666 1.824 0 3.4-.387 4.137-.948m.446-4.468c0 .92-2.052 1.666-4.583 1.666s-4.583-.746-4.583-1.666"
    />
  </svg>
);
export default CoinsSvg;
