import { Button } from 'antd';
import React from 'react';
import ApprovalWhiteIcon from '../../Assests/Svgs/ApprovalWhiteIcon';

interface GoldRequestsHeaderButtonsProps {
  showModal: () => void;
  isApproved?: boolean;
}
const GoldRequestsHeaderButtons: React.FC<GoldRequestsHeaderButtonsProps> = ({
  showModal,
  isApproved,
}) => {
  return (
    <div>
      <Button
        className="flex flex-row items-center text-sm font-semibold gap-2 bg-[#282C74] text-white p-3 py-5"
        onClick={showModal}
        disabled={isApproved}
      >
        <ApprovalWhiteIcon />
        Approve
      </Button>
    </div>
  );
};

export default GoldRequestsHeaderButtons;
