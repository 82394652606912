import * as React from 'react';
const XIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#344054"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.667}
      d="M7.5 7.5h.008m4.992 5h.008m.825-5.833-6.666 6.666m-.555-10.15a3.193 3.193 0 0 0 1.817-.753 3.193 3.193 0 0 1 4.142 0c.512.435 1.148.699 1.818.752a3.193 3.193 0 0 1 2.929 2.93c.053.67.317 1.305.753 1.817a3.193 3.193 0 0 1 0 4.142 3.193 3.193 0 0 0-.753 1.818 3.193 3.193 0 0 1-2.93 2.929 3.192 3.192 0 0 0-1.817.752 3.194 3.194 0 0 1-4.142 0 3.193 3.193 0 0 0-1.817-.752 3.193 3.193 0 0 1-2.93-2.93 3.193 3.193 0 0 0-.752-1.817 3.193 3.193 0 0 1 0-4.142c.435-.512.699-1.148.752-1.818a3.193 3.193 0 0 1 2.93-2.929ZM7.917 7.5a.417.417 0 1 1-.834 0 .417.417 0 0 1 .834 0Zm5 5a.417.417 0 1 1-.834 0 .417.417 0 0 1 .834 0Z"
    />
  </svg>
);
export default XIcon;
