import React, { useContext, useState } from 'react';
import { Button, DatePicker, Dropdown, Form, Radio, Select } from 'antd';
import { ColorModeContext } from '../../contexts/color-mode';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import Roznama from '../../Assests/Svgs/Roznama';
interface Filter {
  field: string;
  value: string;
}

interface FiltrationProps {
  setFiltration: React.Dispatch<React.SetStateAction<Filter[]>>; // Setter for filter array
  searchFormProps: any;
  refetch: () => void;
  setFilters: any;
}

const Filtration: React.FC<FiltrationProps> = ({
  setFiltration,
  refetch,
  searchFormProps,
  setFilters,
}) => {
  const { RangePicker } = DatePicker;
  const [selectedDateRange, setSelectedDateRange] = useState<[string, string]>([
    '',
    '',
  ]);
  const [goldTypeDropdownOpen, setGoldTypeDropdownOpen] = useState(false); // Track if the dropdown is open
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false); // Track if the dropdown is open
  const [clientDropdownOpen, setClientDropdownOpen] = useState(false); // Track if the dropdown is open
  const [deliveryTypeDropdownOpen, setDeliveryTypeDropdownOpen] =
    useState(false); // Track if the dropdown is open
  const [openDropdown, setOpenDropdown] = useState<string | null>(null); // Track the open dropdown
  const { mode } = useContext(ColorModeContext);
  const toggleFilter = (field: string, value: string) => {
    setFiltration((prevFilters) => {
      if (value === 'All') {
        // Remove the filter if the value is 'All'
        return prevFilters.filter((filter) => filter.field !== field);
      } else {
        const updatedFilters = prevFilters.map((filter) => {
          // If a filter with the same field exists, update its value
          if (filter.field === field) {
            return { ...filter, value }; // Replace the value
          }
          return filter; // Keep the filter unchanged
        });

        // Check if the field already exists in the filters
        const fieldExists = prevFilters.some(
          (filter) => filter.field === field
        );

        if (fieldExists) {
          // If the field exists, return the updated filters
          return updatedFilters;
        } else {
          // If the field doesn't exist, add the new filter to the array
          return [...prevFilters, { field, value }];
        }
      }
    });
    // Close the dropdown after selection
    setOpenDropdown(null);
  };

  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    if (!dates) {
      setFilters((currentFilters: any) => {
        // Preserve the user filter and clear other filters
        return currentFilters.filter(
          (filter: { field: string }) => filter.field !== 'start_date'
        );
      }, 'replace');
    }
    if (!dates) {
      setFilters((currentFilters: any) => {
        // Preserve the user filter and clear other filters
        return currentFilters.filter(
          (filter: { field: string }) => filter.field !== 'end_date'
        );
      }, 'replace');
    }
    setSelectedDateRange(dateStrings);
    searchFormProps.form?.setFieldsValue({ start_date: dateStrings[0] });
    searchFormProps.form?.setFieldsValue({ end_date: dateStrings[1] });

    searchFormProps.form?.submit();
  };

  const getDateRangeLabel = () => {
    const [start, end] = selectedDateRange;
    return start && end ? `${start} - ${end}` : 'Date Range';
  };

  // Function to handle dropdown visibility change
  const handleDropdownVisibleChange = (visible: boolean, dropdown: string) => {
    setOpenDropdown(visible ? dropdown : null);
  };

  return (
    <Form
      {...searchFormProps}
      layout="inline"
      className="flex flex-row justify-center 2xl:justify-end w-full 2xl:w-[300%] overflow-x-scroll gap-3 pb-4"
      onValuesChange={() => {
        searchFormProps.form?.submit();
      }}
    >
      <Form.Item name="start_date">
        {/* Date Range Picker */}
        <Dropdown
          overlay={
            <div style={{ padding: '10px' }}>
              <RangePicker
                onChange={handleDateRangeChange}
                format="YYYY-MM-DD"
                style={{ width: '100%' }}
                placeholder={['Start Date', 'End Date']}
              />
            </div>
          }
          onVisibleChange={(visible) =>
            handleDropdownVisibleChange(visible, 'dateRange')
          }
        >
          <Button className={`${mode === 'light' && 'text-[#667085]'}`}>
            <div className="flex flex-row gap-3 justify-center items-center text-[#bfbfbf]">
              {getDateRangeLabel()}
              <div className={`transform transition-transform duration-200`}>
                {/* Replace UpOutlined with your Roznama Icon */}
                <Roznama />{' '}
              </div>
            </div>
          </Button>
        </Dropdown>
      </Form.Item>

      <Form.Item name="end_date" className="hidden" />
      {/* Client Dropdown */}
      <Form.Item name="system_configs_id">
        <div className=" border-[1px] rounded-lg border-[#D0D5DD]  relative w-fit flex flex-row justify-center items-center bg-white">
          <h1>Client Field BackGround </h1>
          {statusDropdownOpen ? <UpOutlined /> : <DownOutlined />}
        </div>
        <Select
          onChange={(value) => {
            searchFormProps.form?.setFieldsValue({ system_configs_id: value });
          }}
          suffixIcon={clientDropdownOpen ? <UpOutlined /> : <DownOutlined />} // Toggle the arrow icon
          onDropdownVisibleChange={(open) => setClientDropdownOpen(open)} // Manage dropdown open state
          dropdownStyle={{ width: 'fit-content', maxWidth: '175px' }}
          className={`absolute top-0 start-0 w-full  ${clientDropdownOpen && mode === 'light' && 'selected font-medium '} ${clientDropdownOpen && mode === 'dark' && 'selected2 font-medium '}`}
          placeholder={`Client`}
          dropdownRender={() => (
            <div>
              <Radio.Group
                onChange={(e) => {
                  searchFormProps.form?.setFieldsValue({
                    system_configs_id: e.target.value,
                  });
                  searchFormProps.form?.submit();
                  toggleFilter('client', e.target.value);
                }}
                value={'clientValue'}
                style={{ padding: '8px' }}
              >
                <Radio
                  className="w-full p-2 cursor-pointer"
                  style={{ display: 'block' }}
                  value="All"
                  onClick={() => {
                    //@ts-ignore
                    setFilters((currentFilters) => {
                      // Preserve the user filter and clear other filters
                      return currentFilters.filter(
                        (filter: { field: string }) =>
                          filter.field !== 'system_configs_id'
                      );
                    }, 'replace');

                    refetch();
                  }}
                >
                  All
                </Radio>
                <Radio
                  className="w-full p-2 cursor-pointer"
                  style={{ display: 'block' }}
                  value="IDH First Class"
                >
                  IDH First Class
                </Radio>
                <Radio
                  className="w-full p-2 cursor-pointer"
                  style={{ display: 'block' }}
                  value="IDH Excellence"
                >
                  IDH Excellence
                </Radio>
                <Radio className="w-full p-2 cursor-pointer" value="Sipes">
                  Sipes
                </Radio>
                <Radio className="w-full p-2 cursor-pointer" value="Shell">
                  Shell
                </Radio>
              </Radio.Group>
            </div>
          )}
        />
      </Form.Item>
      <Form.Item name="gold_type">
        <div className="px-2 py-1 border-[1px] rounded-lg border-[#D0D5DD]  relative w-fit flex flex-row justify-center items-center  bg-white">
          <h1>Gold Type Field BackGrond</h1>
          {statusDropdownOpen ? <UpOutlined /> : <DownOutlined />}
        </div>
        <Select
          onChange={(value) => {
            searchFormProps.form?.setFieldsValue({ gold_type: value });
          }}
          dropdownStyle={{ width: 'fit-content', maxWidth: '210px' }}
          placeholder={`Gold Type`}
          suffixIcon={goldTypeDropdownOpen ? <UpOutlined /> : <DownOutlined />} // Toggle the arrow icon
          onDropdownVisibleChange={(open) => setGoldTypeDropdownOpen(open)} // Manage dropdown open state
          className={`absolute top-0 start-0 w-full  ${goldTypeDropdownOpen && mode === 'light' && 'selected font-medium'} ${goldTypeDropdownOpen && mode === 'dark' && 'selected2 font-medium'}`}
          dropdownRender={() => (
            <div>
              <Radio.Group
                onChange={(e) => {
                  searchFormProps.form?.setFieldsValue({
                    gold_type: e.target.value,
                  });
                  searchFormProps.form?.submit();
                  toggleFilter('gold_type', e.target.value);
                }}
                style={{ padding: '8px' }}
              >
                <Radio
                  className="w-full p-2 cursor-pointer "
                  style={{ display: 'block' }}
                  value="All"
                  onClick={() => {
                    //@ts-ignore
                    setFilters((currentFilters) => {
                      return currentFilters.filter(
                        (filter: { field: string }) =>
                          filter.field !== 'gold_type'
                      );
                    }, 'replace');
                    refetch();
                  }}
                >
                  All
                </Radio>
                <Radio
                  className="w-full p-2 cursor-pointer"
                  value="Quarter Gold Pound"
                >
                  Quarter Gold Pound
                </Radio>
                <Radio
                  className="w-full p-2 cursor-pointer"
                  value="Half Gold Pound"
                >
                  Half Gold Pound
                </Radio>
                <Radio className="w-full p-2 cursor-pointer" value="Gold Pound">
                  Gold Pound
                </Radio>
                <Radio
                  className="w-full p-2 cursor-pointer"
                  value="Ten Grams Pound"
                >
                  Ten Grams Pound
                </Radio>
                <Radio
                  className="w-full p-2 cursor-pointer"
                  value="Five Grams Pound"
                >
                  Five Grams Pound
                </Radio>
                <Radio className="w-full p-2 cursor-pointer" value="Ounce Bar">
                  Ounce Bar
                </Radio>
              </Radio.Group>
            </div>
          )}
        />
      </Form.Item>
      <Form.Item name="delivery_type">
        <div className="px-2 py-1 border-[1px] rounded-lg border-[#D0D5DD]  relative w-fit flex flex-row justify-center items-center gap-4 bg-white">
          <h1>Delivery Type Field</h1>
          {statusDropdownOpen ? <UpOutlined /> : <DownOutlined />}
        </div>
        <Select
          onChange={(value) => {
            searchFormProps.form?.setFieldsValue({ delivery_type: value });
          }}
          suffixIcon={
            deliveryTypeDropdownOpen ? <UpOutlined /> : <DownOutlined />
          } // Toggle the arrow icon
          onDropdownVisibleChange={(open) => setDeliveryTypeDropdownOpen(open)} // Manage dropdown open state
          dropdownStyle={{ width: 'fit-content', maxWidth: '175px' }}
          placeholder={`Delivery Type`}
          className={`absolute top-0 start-0 w-full ${deliveryTypeDropdownOpen && mode === 'light' && 'selected font-medium'} ${deliveryTypeDropdownOpen && mode === 'dark' && 'selected2 font-medium'}`}
          dropdownRender={() => (
            <div>
              <Radio.Group
                onChange={(e) => {
                  searchFormProps.form?.setFieldsValue({
                    delivery_type: e.target.value,
                  });
                  searchFormProps.form?.submit();
                  toggleFilter('delivery_type', e.target.value);
                }}
                style={{ padding: '8px' }}
              >
                <Radio
                  className="w-full p-2 cursor-pointer"
                  value="All"
                  onClick={() => {
                    //@ts-ignore
                    setFilters((currentFilters) => {
                      // Preserve the user filter and clear other filters
                      return currentFilters.filter(
                        (filter: { field: string }) =>
                          filter.field !== 'delivery_type'
                      );
                    }, 'replace');
                    refetch();
                  }}
                >
                  All
                </Radio>
                <Radio
                  className="w-full p-2 cursor-pointer"
                  value="Store Pickup"
                >
                  Store Pickup
                </Radio>
                <Radio className="w-full p-2 cursor-pointer" value="Delivery">
                  Delivery
                </Radio>
              </Radio.Group>
            </div>
          )}
        />
      </Form.Item>
      <Form.Item name="status">
        <div className="px-2 py-1 border-[1px] rounded-lg border-[#D0D5DD]  relative w-fit flex flex-row justify-center items-center gap-4 bg-white ">
          <h1 className="text-black">Statuses Field</h1>
          {statusDropdownOpen ? <UpOutlined /> : <DownOutlined />}
        </div>
        <Select
          onChange={(value) => {
            searchFormProps.form?.setFieldsValue({ status: value });
          }}
          suffixIcon={statusDropdownOpen ? <UpOutlined /> : <DownOutlined />} // Toggle the arrow icon
          onDropdownVisibleChange={(open) => setStatusDropdownOpen(open)} // Manage dropdown open state
          dropdownStyle={{ width: 'fit-content', maxWidth: '140px' }}
          placeholder={`Status`}
          className={`absolute top-0  start-0 w-full ${statusDropdownOpen && mode === 'light' && 'selected font-medium'} ${statusDropdownOpen && mode === 'dark' && 'selected2 font-medium'}`}
          dropdownRender={() => (
            <div>
              <Radio.Group
                onChange={(e) => {
                  searchFormProps.form?.setFieldsValue({
                    status: e.target.value,
                  });
                  searchFormProps.form?.submit();
                  toggleFilter('status', e.target.value);
                }}
                style={{ padding: '8px' }}
              >
                <Radio
                  className="w-full p-2 cursor-pointer"
                  style={{ display: 'block' }}
                  value="All"
                  onClick={() => {
                    //@ts-ignore
                    setFilters((currentFilters) => {
                      // Preserve the user filter and clear other filters
                      return currentFilters.filter(
                        (filter: { field: string }) => filter.field !== 'status'
                      );
                    }, 'replace');
                    refetch();
                  }}
                >
                  All
                </Radio>
                <Radio
                  className="w-full p-2 cursor-pointer"
                  style={{ display: 'block' }}
                  value="Approved"
                >
                  Approved
                </Radio>
                <Radio className="w-full p-2 cursor-pointer" value="Pending">
                  Pending
                </Radio>

                <Radio className="w-full p-2 cursor-pointer" value="Picked Up">
                  Picked Up
                </Radio>
              </Radio.Group>
            </div>
          )}
        />
      </Form.Item>
    </Form>
  );
};

export default Filtration;
