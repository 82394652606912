import * as React from "react"
const RefreshIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#045DA0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.667}
      d="M1.667 8.333S3.337 6.057 4.694 4.7a7.5 7.5 0 1 1-1.902 7.385m-1.127-3.75v-5m0 5h5"
    />
  </svg>
)
export default RefreshIcon
