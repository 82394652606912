import React, { useContext } from 'react';
import RefreshIcon from '../../Assests/Svgs/RefreshIcon';
import { ColorModeContext } from '../../contexts/color-mode';
interface Filter {
  field: string;
  value: string;
}
interface FilterBarProps {
  filtration: Filter[];
  removeFilter: (field: string, value: string) => void;
  setFiltration: (filters: Filter[]) => void; // Setter for filter array
  handleReset: () => void;
}
const FilterBar: React.FC<FilterBarProps> = ({
  filtration,
  removeFilter,
  setFiltration,
  handleReset,
}) => {
  const cleanArrayData = () => {
    handleReset();
    setFiltration([]);
  };
  const { mode } = useContext(ColorModeContext);

  return (
    <>
      {filtration.length > 0 && (
        <div className="py-4 ">
          <div
            className={`flex flex-row justify-between items-center ${mode === 'light' && 'bg-[#F9FAFB]'}  px-4 rounded-xl overflow-x-scroll`}
          >
            <div className="flex flex-row gap-[10px] p-2  justify-start items-center ">
              {filtration.map((filter) => {
                return (
                  <span
                    className={`py-[0.1rem] px-2 border-2 ${mode === 'light' && 'border-[#3E4784]'} rounded-full text-nowrap`}
                  >
                    {filter.value}
                    <span
                      className="ps-1 cursor-pointer"
                      onClick={() => {
                        if (filtration.length === 1) {
                          cleanArrayData();
                        } else {
                          if (filter.field === 'client') {
                            removeFilter('system_configs_id', filter.value);
                            removeFilter(filter.field, filter.value);
                          } else {
                            removeFilter(filter.field, filter.value);
                          }
                        }
                      }}
                    >
                      x
                    </span>
                  </span>
                );
              })}
            </div>
            <div
              className="flex justify-center items-center gap-2 w-fit cursor-pointer"
              onClick={cleanArrayData}
            >
              <RefreshIcon />
              <h1 className="text-[#045DA0] text-nowrap">Reset All</h1>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FilterBar;
