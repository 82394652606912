import * as React from 'react';
const ApprovalModalIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={49}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="#DCFAE6"
      d="M.5 24c0-13.255 10.745-24 24-24s24 10.745 24 24-10.745 24-24 24S.5 37.255.5 24Z"
    />
    <path
      stroke="#079455"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m21.5 23 3 3 10-10m-6-1h-8.2c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311c-.327.642-.327 1.482-.327 3.162v8.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C17.78 33 18.62 33 20.3 33h8.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311c.327-.642.327-1.482.327-3.162V24"
    />
  </svg>
);
export default ApprovalModalIcon;
